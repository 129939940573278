import isEqual from "lodash/isEqual";
import { DatoJsonField } from "src/types";
import { isRecordStringString, trimChars } from "./utils";

const tenantIdsConfigString =
	process.env.EMIL_AVAILABLE_TENANT_SLUGS?.toString() ?? "";
const tenantId = process.env.EMIL_TENANT_SLUG;

if (!tenantId) {
	throw new Error("EMIL_TENANT_SLUG must be configured!");
}

if (tenantIdsConfigString.length < 1) {
	throw new Error("EMIL_AVAILABLE_TENANT_SLUGS is empty?");
}

export const tenantIds = tenantIdsConfigString
	.split(",")
	.map((s) => trimChars(s));

if (!tenantIds.includes(tenantId)) {
	throw new Error("EMIL_TENANT_SLUG must be in EMIL_AVAILABLE_TENANT_SLUGS");
}

export const isValidValues = (
	field: unknown,
): field is Record<string, string> => {
	if (!isRecordStringString(field)) {
		return false;
	}

	const keys = Object.keys(field);
	const values = Object.values(field);

	return (
		values.every(Boolean) &&
		keys.length === tenantIds.length &&
		isEqual(keys.sort(), tenantIds.sort())
	);
};

/**
 * Resolves the JSON of a "Tenant Aware Field" to the value for the current tenant, e.g.
 * product codes that are editable in the CMS differ for Emil tenants, so we need to resolve
 * all of them.
 *
 * @param field The field data to resolve.
 * @param overrideTenant The tenant to use instead of the configured one.
 * @returns The resolved string.
 * @throws If the field is not a string or a valid tenant aware field.
 */

export const resolveTenantAware = (field: DatoJsonField): string => {
	if (typeof field === "string") {
		// eslint-disable-next-line no-console
		console.warn(`Unnecessary tenant resolving: ${field}`);

		return field;
	}

	if (!isValidValues(field)) {
		// eslint-disable-next-line no-console
		console.warn(`Malformed value: ${JSON.stringify(field)}}`);
	}

	if (!isRecordStringString(field) || !(tenantId in field)) {
		throw new Error(
			`EMIL_TENANT_SLUG is not configured in ${JSON.stringify(field)}}`,
		);
	}

	const value = field[tenantId as keyof DatoJsonField];

	if (!value) {
		throw new Error(
			`EMIL_TENANT_SLUG is not configured in ${JSON.stringify(field)}}`,
		);
	}

	if (typeof value !== "string") {
		throw new Error(`Malformed value type: ${typeof value}}`);
	}

	return value;
};

// 🔬 jest unit tested
