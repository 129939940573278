import { isValid as isValidIban } from "iban";
import { isMessageKey, MessageKey } from "src/i18n/i18n";
import { AnyRecord } from "src/types";
import {
	ErrorMapCtx,
	TypeOf,
	z as zod,
	ZodError,
	ZodIssueCode,
	ZodIssueOptionalMessage,
	ZodObject,
	ZodRawShape,
	ZodTypeAny,
} from "zod";
import { fromEntries, isPostCode } from "./utils";

type ErrorMapFunction = (
	issue: ZodIssueOptionalMessage,
	_ctx: ErrorMapCtx,
) => {
	message: MessageKey;
};

// allows us to include globalErrorMap and any other default configurations by default
export const z = zod;

const mapErrors: ErrorMapFunction = (issue, ctx) => {
	if (issue.code === ZodIssueCode.too_small) {
		if (ctx.data === "") {
			return {
				message: "zod.required",
			};
		}
	}

	if (issue.code === ZodIssueCode.invalid_string) {
		if (ctx.data === "") {
			return {
				message: "zod.required",
			};
		}

		if (issue.validation === "email") {
			return {
				message: "zod.email",
			};
		}
	}

	if (issue.code === ZodIssueCode.invalid_type) {
		if (issue.received === "undefined") {
			return { message: "zod.required" };
		}

		if (issue.expected === "date") {
			return { message: "zod.date" };
		}

		if (issue.expected === "number") {
			return { message: "zod.number" };
		}
	}

	if (issue.path.includes("password")) {
		return { message: "zod.password" };
	}

	if (isMessageKey(`zod.${issue.code}`)) {
		return { message: `zod.${issue.code}` as MessageKey };
	}

	if (issue.code === "custom") {
		return { message: issue.message as MessageKey };
	}

	return { message: "zod.default" };
};

zod.setErrorMap(mapErrors);

export const refinePostCode = [
	(val: unknown) => typeof val === "string" && isPostCode(val),
	{
		message: "zod.postCode",
	},
] as const;

export const refineIban = [
	(val: unknown) => typeof val === "string" && isValidIban(val),
	{
		message: "zod.iban",
	},
] as const;

/**
 * Parses an object with a zod schema and returns a tuple of the parsed object and any errors:
 * The parsed object will only contain the keys that are present in the schema.
 *
 * @param schema a zod object schema
 * @param data any object
 * @param fallbackCallback a callback to return a fallback value if the value is not valid
 * @returns
 */
export const partiallyParse = <S extends ZodObject<ZodRawShape>>(
	schema: S,
	data: AnyRecord,
	fallbackCallback: (d: unknown) => any = () => undefined,
) => {
	const partialSchema = schema.partial();
	const entries: Array<[string, ZodTypeAny]> = Object.entries(
		partialSchema.shape,
	);

	const singleResults: Array<{
		key: string;
		value: unknown;
		error: ZodError | null;
		isSuccess: boolean;
	}> = entries.map(([key, subSchema]) => {
		const value = data[key];

		const result = subSchema.safeParse(value);
		const fallback = fallbackCallback(value);

		if (result.success) {
			return { key, value: result.data, error: null, isSuccess: true };
		}

		return { key, value: fallback, error: result.error, isSuccess: true };
	});

	return {
		result: fromEntries(
			singleResults
				.filter(({ value }) => Boolean(value))
				.map(({ key, value }) => [key, value]),
		) as TypeOf<S>,
		errors: fromEntries(
			singleResults
				.filter(({ error }) => Boolean(error))
				.map(({ key, error }) => [key, error]),
		),
	};
};

// 🔬 skip / partially unit tested
